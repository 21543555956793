import React from "react"
import PropTypes from "prop-types"
import { Button } from "gatsby-interface"
import { FaThumbtack } from "react-icons/fa"

const propTypes = {
  siteName: PropTypes.string.isRequired,
}

function SiteName({ siteName, className, onPinSite, isPinned, enablePinning }) {
  return (
    <h3
      className={className}
      css={theme => {
        return {
          fontSize: theme.fontSizes[2],
          color: theme.colors.grey[90],
          marginBottom: `0`,
        }
      }}
    >
      {siteName}{" "}
      {enablePinning && (
        <Button
          size="S"
          rightIcon={<FaThumbtack />}
          variant={isPinned ? "PRIMARY" : "SECONDARY"}
          onClick={onPinSite}
        >
          {isPinned ? "Unpin" : "Pin"}
        </Button>
      )}
    </h3>
  )
}

SiteName.propTypes = propTypes

export default SiteName
