import * as React from "react"
import * as Yup from "yup"
import {
  MemberFieldsValues,
  createMemberFieldsValidationSchema,
} from "./MemberFieldsGroup"

type FormValues = Omit<MemberFieldsValues, "email">

const validationSchema = createMemberFieldsValidationSchema({
  includeEmail: false,
})

export function useMemberValidation() {
  type FormErrors = Record<keyof FormValues, string | null>

  const [errors, setErrors] = React.useState<FormErrors>({
    role: null,
    accessAllSites: null,
    sites: null,
  })

  const validateForm = (formState: FormValues): boolean => {
    const nextErrors: FormErrors = {
      role: null,
      accessAllSites: null,
      sites: null,
    }
    let isValid = true

    try {
      validationSchema.validateSync(formState, {
        abortEarly: false,
      })
    } catch (e) {
      isValid = false
      const validationError = e as Yup.ValidationError

      validationError.inner.forEach(error => {
        nextErrors[error.path as keyof FormValues] = error.message
      })
    }
    setErrors(nextErrors)

    return isValid
  }

  return [validateForm, errors] as const
}
