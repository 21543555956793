import * as React from "react"
import { useCurrentUser } from "@modules/auth"

export function usePinnedSites() {
  const { currentUser } = useCurrentUser()
  const userId = currentUser?.id
  const storageKey = userId ? `pinnedSitesIds:${userId}` : null

  const [pinnedSitesIds, setPinnedSitesIds] = React.useState<string[]>(() => {
    if (!storageKey) {
      return []
    }
    const pinnedSitesIdsJson = window.localStorage.getItem(storageKey)
    const pinnedSitesIdsList = pinnedSitesIdsJson
      ? JSON.parse(pinnedSitesIdsJson)
      : []

    return Array.isArray(pinnedSitesIdsList)
      ? (pinnedSitesIdsList as string[])
      : []
  })

  React.useEffect(() => {
    if (!storageKey) {
      return
    }
    window.localStorage.setItem(storageKey, JSON.stringify(pinnedSitesIds))
  }, [pinnedSitesIds])

  const pinnedSitesIdsHash = React.useMemo(() => {
    const hash: { [k: string]: boolean } = {}
    for (const siteId of pinnedSitesIds) {
      hash[siteId] = true
    }
    return hash
  }, [pinnedSitesIds])

  const pinSite = (siteId: string) => {
    setPinnedSitesIds(prevValue => {
      const alreadyPinned = prevValue.includes(siteId)
      return alreadyPinned
        ? prevValue.filter(id => id !== siteId)
        : [...prevValue, siteId]
    })
  }

  return {
    pinnedSitesIdsHash,
    pinSite,
  }
}
