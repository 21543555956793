import React from "react"
import PropTypes from "prop-types"
import { BuildRunnerType, PreviewStatus } from "@modules/graphql/types"
import { useSiteChangedSubscription } from "@modules/site/shared/queries.generated"
import SiteName from "./SiteName"
import SiteDetailsLink from "./SiteDetailsLink"
import { SiteBuildStatus } from "./SiteBuildStatus"
import { SiteCardLayout, siteDetailsLinkCss } from "./layouts/SiteCardLayout"
import SiteBranch from "@modules/site/shared/components/SiteBranch"
import { getPreviewStatus } from "@modules/build/shared/utils"

const propTypes = {
  id: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  latestBuild: PropTypes.object,
  latestPreview: PropTypes.object,
  previewStatus: PropTypes.oneOf(Object.values(PreviewStatus)).isRequired,
  buildsEnabled: PropTypes.bool,
  previewBuildsEnabled: PropTypes.bool,
  enablePinning: PropTypes.bool,
}

function SiteCardOld({
  id: siteId,
  organizationId,
  latestBuild,
  latestPreview,
  name: siteName,
  repositoryId,
  previewStatus,
  buildsEnabled,
  previewBuildsEnabled,
  branch,
  onPinSite,
  isPinned,
  enablePinning,
}) {
  useSiteChangedSubscription({ variables: { id: siteId } })

  const buildStatus = (latestBuild && latestBuild.buildStatus) || ``
  const buildStartedAt = (latestBuild && latestBuild.startedAt) || ``

  return (
    <SiteCardLayout data-cy={`${siteName}-card`}>
      <SiteName
        siteName={siteName}
        css={{ gridArea: "head" }}
        onPinSite={onPinSite}
        isPinned={isPinned}
        enablePinning={enablePinning}
      />

      {buildsEnabled && (
        <SiteBuildStatus
          css={{ gridArea: "buildStatus" }}
          siteId={siteId}
          organizationId={organizationId}
          buildStatus={buildStatus}
          runnerType={BuildRunnerType.Builds}
          buildStartedAt={buildStartedAt}
          data-testid="build-status"
          a11yId={`SiteCard--${siteId}--BuildStatus`}
        />
      )}

      {previewBuildsEnabled && (
        <SiteBuildStatus
          css={{ gridArea: "previewStatus" }}
          siteId={siteId}
          organizationId={organizationId}
          buildStatus={getPreviewStatus(
            latestPreview?.buildStatus || ``,
            previewStatus
          )}
          runnerType={BuildRunnerType.Preview}
          repositoryId={repositoryId}
          data-testid="preview-status"
          a11yId={`SiteCard--${siteId}--PreviewStatus`}
        />
      )}

      {branch && (
        <SiteBranch
          css={{
            gridArea: "branch",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {branch}
        </SiteBranch>
      )}

      <SiteDetailsLink
        css={siteDetailsLinkCss}
        siteId={siteId}
        organizationId={organizationId}
        variant="PRIMARY"
      />
    </SiteCardLayout>
  )
}

SiteCardOld.propTypes = propTypes

export default SiteCardOld
