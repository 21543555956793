import * as React from "react"
import * as Yup from "yup"
import {
  InputFieldBlock,
  SelectFieldBlock,
  CheckboxGroupFieldBlock,
  CheckboxGroupFieldBlockOption,
  Spacer,
  FormFieldset,
  FormLegend,
  RadioButtonFieldBlock,
  RadioButtonFieldBlockOption,
} from "gatsby-interface"
import {
  ui as uiText,
  members as membersText,
  memberFields as memberFieldsText,
} from "@modules/locales/default.json"
import { CloudRole } from "@modules/graphql/types"
import { useOrganizationSitesNameFieldsFragment } from "@modules/organization/fragments.generated"
import { interpolateMessage } from "@modules/locales"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { AllowedMemberRole, MemberFormValues } from "./utils"

export type MemberFieldsValues = MemberFormValues

type ValidationArgsNoEmail = {
  includeEmail: false
}

type ValidationArgsWithEmail = {
  includeEmail: true
  existingEmails: string[]
}

type ValidationArgs = ValidationArgsNoEmail | ValidationArgsWithEmail

export function createMemberFieldsValidationSchema(
  params: ValidationArgsNoEmail
): Yup.ObjectSchema<
  Yup.Shape<
    Omit<MemberFieldsValues, "email">,
    Omit<MemberFieldsValues, "email">
  >
>
export function createMemberFieldsValidationSchema(
  params: ValidationArgsWithEmail
): Yup.ObjectSchema<Yup.Shape<MemberFieldsValues, MemberFieldsValues>>

export function createMemberFieldsValidationSchema(params: ValidationArgs) {
  const baseSchema = Yup.object<Omit<MemberFieldsValues, "email">>().shape<
    Omit<MemberFieldsValues, "email">
  >({
    role: Yup.mixed<AllowedMemberRole>()
      .oneOf([CloudRole.Reader, CloudRole.Contributor, CloudRole.Owner])
      .required(uiText.messages.validationIsRequired),
    accessAllSites: Yup.boolean(),
    sites: Yup.array()
      .of(Yup.string())
      .when("accessAllSites", {
        is: false,
        then: Yup.array()
          .of(Yup.string())
          .required(memberFieldsText.messages.validationSitesRequired),
      }),
  })

  if (params.includeEmail) {
    return baseSchema
      .shape<Pick<MemberFieldsValues, "email">>({
        email: Yup.string()
          .email(uiText.messages.validationInvalidEmail)
          .required(uiText.messages.validationIsRequired),
      })
      .test(
        "unique",
        memberFieldsText.messages.validationEmailUnique,
        validateUniqueEmail
      )
      .test(
        "notAnExistingEmail",
        memberFieldsText.messages.validationEmailNotAMember,
        function(value: MemberFieldsValues) {
          return params.existingEmails.includes(value.email)
            ? this.createError({ path: `${this.path}.email` })
            : true
        }
      ) as Yup.ObjectSchema<Yup.Shape<MemberFieldsValues, MemberFieldsValues>>
  }

  return baseSchema
}

// "this" here is a fake parameter to tell TS that validateUniqueEmail expects its "this" to be Yup.TestContext
// If we were to .bind or .apply it with a different context then TS will report an error
// See also https://www.typescriptlang.org/docs/handbook/functions.html#this-parameters
function validateUniqueEmail(
  this: Yup.TestContext,
  currentMember: MemberFieldsValues
) {
  if (!currentMember.email) {
    return true
  }
  const otherMembers: MemberFieldsValues[] = this.parent.filter(
    (member: MemberFieldsValues) => member !== currentMember
  )

  const isDuplicate = otherMembers.some(
    otherMember => otherMember.email === currentMember.email
  )

  return isDuplicate ? this.createError({ path: `${this.path}.email` }) : true
}

const roleOptions: { value: AllowedMemberRole; label: string }[] = [
  {
    value: CloudRole.Reader,
    label: membersText.morphemes.viewer,
  },
  {
    value: CloudRole.Contributor,
    label: membersText.morphemes.editor,
  },
  // TODO bring back Owner role option when we can support it properly
  // https://app.clubhouse.io/gatsbyjs/story/15654/bring-back-owner-role-to-add-modify-member-form-when-we-can-support-it-properly
  // {
  //   value: CloudRole.Owner,
  //   label: membersText.morphemes.owner,
  // },
]

const roleOptionHints: Record<AllowedMemberRole, string> = {
  [CloudRole.Reader]: memberFieldsText.messages.roleHintViewer,
  [CloudRole.Contributor]: memberFieldsText.messages.roleHintEditor,
  [CloudRole.Owner]: memberFieldsText.messages.roleHintOwner,
}

const SITE_ACCESS_ALL = `ALL_SITES`
const SITE_ACCESS_SOME = `SOME_SITES`

const siteAccessOptions: RadioButtonFieldBlockOption[] = [
  {
    value: SITE_ACCESS_ALL,
    label: memberFieldsText.labels.allowAllSitesAccess,
  },
  {
    value: SITE_ACCESS_SOME,
    label: memberFieldsText.labels.allowSomeSitesAccess,
  },
]

type MemberErrors = Partial<Record<keyof MemberFieldsValues, string | null>>

export type MemberFieldsGroupProps = {
  organizationId: string
  name?: string
  values: MemberFieldsValues
  onChange: (values: MemberFieldsValues) => void
  errors?: MemberErrors | null
  includeEmail?: boolean
}

export function MemberFieldsGroup({
  organizationId,
  name = `member`,
  values,
  onChange,
  errors,
  includeEmail = true,
}: MemberFieldsGroupProps) {
  const [touched, setTouched] = React.useState<
    Record<keyof MemberFieldsValues, boolean>
  >({
    email: false,
    role: false,
    accessAllSites: false,
    sites: false,
  })

  const onEmailChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    onChange({
      ...values,
      email: e.target.value,
    })
  }

  const onRoleChange: React.ChangeEventHandler<HTMLSelectElement> = e => {
    const nextRole = e.target.value as AllowedMemberRole
    onChange({
      ...values,
      role: nextRole,
      accessAllSites:
        nextRole === CloudRole.Owner ? true : values.accessAllSites,
    })
  }

  const onAccessAllSitesChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    onChange({
      ...values,
      accessAllSites: e.target.value === SITE_ACCESS_ALL,
    })
  }

  const onSitesChange: MemberSiteAccessFieldProps["onChange"] = sites => {
    onChange({
      ...values,
      sites,
    })
  }

  const touchField = (field: keyof MemberFieldsValues) => {
    setTouched(prevTouched => ({
      ...prevTouched,
      [field]: true,
    }))
  }

  const isOwnerRole = values.role === CloudRole.Owner

  return (
    <React.Fragment>
      {includeEmail && (
        <InputFieldBlock
          id={`${name}.email`}
          name={`${name}.email`}
          label={memberFieldsText.labels.email}
          labelSize="S"
          value={values.email}
          onChange={onEmailChange}
          onBlur={() => {
            touchField("email")
          }}
          error={touched.email ? errors?.email : null}
        />
      )}
      <Spacer size={5} />
      <SelectFieldBlock
        id={`${name}.role`}
        name={`${name}.role`}
        label={memberFieldsText.labels.role}
        options={roleOptions}
        labelSize="S"
        value={values.role}
        onChange={onRoleChange}
        onBlur={() => {
          touchField("role")
        }}
        error={touched.role ? errors?.role : null}
        hint={roleOptionHints[values.role as AllowedMemberRole]}
      />
      <Spacer size={5} />
      <FormFieldset>
        <FormLegend css={visuallyHiddenCss}>
          {memberFieldsText.labels.siteAccess}
        </FormLegend>
        <RadioButtonFieldBlock
          id={`${name}.accessAllSites`}
          name={`${name}.accessAllSites`}
          label={memberFieldsText.labels.siteAccess}
          labelSize="S"
          options={siteAccessOptions}
          value={values.accessAllSites ? SITE_ACCESS_ALL : SITE_ACCESS_SOME}
          onChange={onAccessAllSitesChange}
          onBlur={() => {
            touchField("accessAllSites")
          }}
          error={touched.accessAllSites ? errors?.accessAllSites : null}
          hint={
            isOwnerRole
              ? memberFieldsText.messages.ownerSiteAccessDisclaimer
              : null
          }
          disabled={isOwnerRole}
        />
        {!values.accessAllSites && (
          <React.Fragment>
            <MemberSiteAccessField
              organizationId={organizationId}
              name={`${name}.sites`}
              sites={values.sites}
              onChange={onSitesChange}
              onTouch={() => {
                touchField("sites")
              }}
              error={touched.sites ? errors?.sites : null}
              disabled={isOwnerRole}
            />
          </React.Fragment>
        )}
      </FormFieldset>
    </React.Fragment>
  )
}

function isInput(element: HTMLElement): element is HTMLInputElement {
  return element?.tagName === `INPUT`
}

type MemberSiteAccessFieldProps = {
  organizationId: string
  name: string
  sites: MemberFieldsValues["sites"]
  onChange: (value: string[]) => void
  error: React.ReactNode | null
  disabled?: boolean
  onTouch: () => void
}

function MemberSiteAccessField({
  organizationId,
  name,
  sites,
  onChange,
  error,
  disabled = false,
  onTouch,
}: MemberSiteAccessFieldProps) {
  const { data } = useOrganizationSitesNameFieldsFragment(organizationId)

  const optionsData = React.useMemo(() => {
    const orgRepos = data?.repositories ?? []
    const sites = []

    for (const repo of orgRepos) {
      sites.push(...(repo.sites ?? []))
    }

    const siteOptions: CheckboxGroupFieldBlockOption[] = sites.map(site => ({
      value: site.id,
      label: site.publicName || site.name,
    }))

    return {
      siteOptions,
      siteOptionValues: siteOptions.map(option => option.value),
    }
  }, [data, sites])

  const handleSitesChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { checked, value: selectedSite } = e.currentTarget
    const currentSites = sites

    let nextSites: string[]

    if (!checked) {
      nextSites = currentSites.filter(
        optionValue => optionValue !== selectedSite
      )
    } else {
      nextSites = [...currentSites, selectedSite]
    }

    return onChange(nextSites)
  }

  return (
    <CheckboxGroupFieldBlock
      id={name}
      name={name}
      value={sites}
      label={
        <span css={visuallyHiddenCss}>{memberFieldsText.labels.pickSites}</span>
      }
      hint={
        sites.length === 0
          ? memberFieldsText.messages.sitesSelectedNone
          : sites.length === optionsData.siteOptions.length
          ? memberFieldsText.messages.sitesSelectedAll
          : interpolateMessage<"count">(
              memberFieldsText.messages.sitesSelectedNum,
              { count: sites.length }
            )
      }
      options={optionsData.siteOptions}
      labelSize="S"
      onChange={handleSitesChange}
      onBlur={e => {
        const blurTarget = e.relatedTarget as
          | HTMLElement
          | HTMLInputElement
          | null
        if (
          blurTarget &&
          isInput(blurTarget) &&
          blurTarget?.name === e.currentTarget.name
        ) {
          return
        }
        onTouch()
      }}
      error={error}
      disabled={disabled}
      css={theme => ({
        // TODO this should probably be added directly to gatsby-interface
        // https://app.clubhouse.io/gatsbyjs/story/15336/add-support-for-scrollable-checkbox-groups-to-gatsby-interface
        "& > div > div:first-of-type": {
          border: `1px solid ${theme.colors.grey[30]}`,
          borderRadius: theme.radii[2],
          paddingTop: theme.space[5],
          paddingBottom: theme.space[5],
          paddingLeft: theme.space[5],
          paddingRight: theme.space[5],
          maxHeight: `150px`,
          overflow: `auto`,
          background: disabled ? theme.colors.grey[10] : undefined,
        },
      })}
    />
  )
}
