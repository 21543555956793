import { orgSettingsParentSections } from "../constants"
import { getPathToOrgSettings } from "@modules/organization/shared/utils"

export function getPathToOrgSettingsSection(organizationId, section) {
  const parentSection = orgSettingsParentSections[section]
  const basePath = getPathToOrgSettings(organizationId)

  if (parentSection) {
    return `${basePath}/${parentSection}#${section}`
  }

  return `${basePath}/${section}`
}
