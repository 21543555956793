import * as React from "react"
import {
  CloudResourceType,
  Contributor as ContributorType,
} from "@modules/graphql/types"
import {
  UpdateContributorRolesMutation,
  useUpdateContributorRolesMutation,
} from "@modules/organization/contributors/queries.generated"

import {
  OrganizationSitesNameFieldsFragment,
  OrganizationSitesNameFieldsFragmentDoc,
} from "@modules/organization/fragments.generated"

import {
  OrganizationNameFieldsFragment,
  OrganizationNameFieldsFragmentDoc,
} from "@modules/organization/Settings/fragments.generated"
import {
  ContributorUpdateFieldsFragment,
  ContributorUpdateFieldsFragmentDoc,
} from "@modules/organization/contributors/fragments.generated"
import {
  useShowSuccessToast,
  StyledPanelBoilerplate,
  StyledPanelBodySection,
} from "gatsby-interface"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import { ModifyMemberForm, ModifyMemberValues } from "../ModifyMemberForm"
import { modifyMember as modifyMemberText } from "@modules/locales/default.json"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { commonMessageTags, FormattedMessage } from "@modules/locales"
import {
  getContributorRoleByAllowedRole,
  memberFieldsToCloudResources,
} from "../utils"
import { MutationUpdaterFn } from "apollo-client"

export type ModifyMemberPanelModalProps = {
  isOpen: boolean
  closeModal: () => void
  member: ContributorType
  organizationId: string
}

export function ModifyMemberPanelModal({
  isOpen,
  closeModal,
  member,
  organizationId,
}: ModifyMemberPanelModalProps) {
  const { trackSegment } = useTracker()
  const [modifyMember, { loading, errorAlert }] = useModifyMember({
    memberId: member.id,
    organizationId,
  })

  return (
    <StyledPanelBoilerplate
      aria-label={modifyMemberText.headers.modifyMemberTitle}
      header={modifyMemberText.headers.modifyMemberTitle}
      isOpen={isOpen}
      onClose={closeModal}
    >
      {errorAlert && (
        <StyledPanelBodySection css={{ paddingBottom: 0 }}>
          {errorAlert}
        </StyledPanelBodySection>
      )}
      <ModifyMemberForm
        member={member}
        organizationId={organizationId}
        onSubmit={modifiedMember => {
          trackSegment({
            type: SegmentEventType.Track,
            event: `Submitted form to modify a member's permissions`,
            properties: {
              location: `Members tab - "Modify Member" modal`,
            },
          })
          return modifyMember(modifiedMember, () => {
            closeModal()
          })
        }}
        onCancel={closeModal}
        loading={loading}
      />
    </StyledPanelBoilerplate>
  )
}

function useModifyMember({
  memberId,
  organizationId,
}: {
  memberId: string
  organizationId: string
}) {
  const [setError, errorAlert] = useTriggerErrorAlert()
  const showSuccessToast = useShowSuccessToast()
  const [mutate, { loading }] = useUpdateContributorRolesMutation()

  const modifyMember = (
    modifiedMember: ModifyMemberValues,
    onSuccess: () => void
  ) => {
    return mutate({
      variables: {
        contributorId: memberId,
        organizationId,
        resources: memberFieldsToCloudResources(organizationId, modifiedMember),
      },
      update: updateMemberFieldsCache({
        memberId,
        organizationId,
        modifiedMember,
      }),
    })
      .then(() => {
        showSuccessToast(
          <FormattedMessage<never, "strong">
            message={modifyMemberText.messages.memberModifyConfirmation}
            tags={commonMessageTags}
          />
        )
        onSuccess()
      })
      .catch(err => setError(err))
  }

  return [modifyMember, { loading, errorAlert }] as const
}

function updateMemberFieldsCache({
  memberId,
  organizationId,
  modifiedMember,
}: {
  memberId: string
  organizationId: string
  modifiedMember: ModifyMemberValues
}): MutationUpdaterFn<UpdateContributorRolesMutation> {
  return (cache, data) => {
    if (!data.data?.updateContributorRoles?.validation?.success) {
      return
    }

    const orgNameFragment = cache.readFragment<OrganizationNameFieldsFragment>({
      id: `Organization:${organizationId}`,
      fragment: OrganizationNameFieldsFragmentDoc,
    })

    const siteAccessOptionsFragment = cache.readFragment<
      OrganizationSitesNameFieldsFragment
    >({
      id: `Organization:${organizationId}`,
      fragment: OrganizationSitesNameFieldsFragmentDoc,
    })

    const orgRepos = siteAccessOptionsFragment?.repositories ?? []
    const sites = []

    for (const repo of orgRepos) {
      sites.push(...(repo.sites ?? []))
    }

    const siteNames: { [k: string]: string } = (sites ?? []).reduce(
      (names, site) => {
        return {
          ...names,
          [site.id]: site.publicName || site.name,
        }
      },
      {}
    )

    cache.writeFragment<ContributorUpdateFieldsFragment>({
      id: `Contributor:${memberId}`,
      fragment: ContributorUpdateFieldsFragmentDoc,
      data: {
        id: memberId,
        contributorRole: getContributorRoleByAllowedRole(modifiedMember.role),
        resources: memberFieldsToCloudResources(
          organizationId,
          modifiedMember
        ).map(resource => ({
          ...resource,
          name:
            resource.resourceType === CloudResourceType.Organization
              ? orgNameFragment?.name
              : siteNames[resource.resourceId],
          __typename: `CloudResource`,
        })),
        __typename: `Contributor`,
      },
    })
  }
}
