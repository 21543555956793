import * as React from "react"
import { format } from "date-fns"
import { MdCreditCard, MdTimelapse, MdToday } from "react-icons/md"
import { ThemeCss, VisuallyHidden } from "gatsby-interface"
import { paymentInformation as paymentInformationText } from "@modules/locales/default.json"
import { BillingInterval } from "@modules/graphql/types"

const listCss: ThemeCss = theme => ({
  display: `grid`,
  gridTemplateColumns: `min-content auto`,
  gridRowGap: theme.space[1],
  gridColumnGap: theme.space[3],
  alignItems: `center`,
  margin: 0,
  [theme.mediaQueries.phablet]: {
    gridTemplateColumns: `min-content auto 1fr`,
    gridRowGap: theme.space[3],
    gridColumnGap: theme.space[5],
  },
})

const cellCss: ThemeCss = theme => ({
  margin: 0,
  color: theme.colors.grey[90],
  fontSize: theme.fontSizes[1],
})

const iconCss: ThemeCss = theme => ({
  color: theme.colors.grey[40],
  display: `block`,
})

const cardNumberCellCss: ThemeCss = theme => [
  cellCss(theme),
  {
    textTransform: `capitalize`,
  },
]

const valueCellCss: ThemeCss = theme => [
  cellCss(theme),
  {
    fontWeight: theme.fontWeights.bold,
    gridColumn: `1 / span 2`,
    marginBottom: theme.space[2],
    [theme.mediaQueries.phablet]: {
      paddingLeft: theme.space[10],
      marginBottom: 0,
      gridColumn: `initial`,
    },
  },
]

export type CardInformationProps = {
  cardBrand?: string
  cardNumber?: string
  interval: BillingInterval
  formattedAmount?: string
  amount?: string
  nextBillingDate?: string
}

export function CardInformation({
  cardBrand,
  cardNumber,
  interval,
  formattedAmount,
  amount,
  nextBillingDate,
}: CardInformationProps) {
  return (
    <dl css={listCss} className="fs-block">
      {cardNumber && (
        <React.Fragment>
          <dt css={cellCss}>
            <VisuallyHidden>
              {paymentInformationText.labels.cardNumber}
            </VisuallyHidden>
            <MdCreditCard css={iconCss} />
          </dt>
          {cardBrand ? (
            <dd css={cardNumberCellCss}>{cardBrand.toLowerCase()}</dd>
          ) : (
            <div />
          )}
          <dd css={valueCellCss}>**** **** **** {cardNumber}</dd>
        </React.Fragment>
      )}
      <dt css={cellCss}>
        <VisuallyHidden>
          {paymentInformationText.labels.billingInterval}
        </VisuallyHidden>
        <MdTimelapse css={iconCss} />
      </dt>
      <dd css={cellCss}>
        {interval === BillingInterval.Annual
          ? paymentInformationText.messages.billedAnnually
          : paymentInformationText.messages.billedMonthly}
      </dd>
      <dd css={valueCellCss}>{`${formattedAmount}`.replace(/\.00$/, ``)}</dd>
      {amount && nextBillingDate && (
        <React.Fragment>
          <dt css={cellCss}>
            <VisuallyHidden>
              {paymentInformationText.labels.nextPayment}
            </VisuallyHidden>
            <MdToday css={iconCss} />
          </dt>
          <dd aria-hidden css={cellCss}>
            {paymentInformationText.messages.nextPaymentReceived}
          </dd>
          <dd css={valueCellCss}>
            {format(new Date(nextBillingDate), `yyyy-MM-dd`)}
          </dd>
        </React.Fragment>
      )}
    </dl>
  )
}
