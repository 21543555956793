import * as React from "react"
import { ThemeCss, ProgressBar, Spacer, Theme } from "gatsby-interface"
import {
  BillingInformation,
  PlatformLimitSummary,
} from "@modules/graphql/types"
import {
  supportedTiers,
  SupportedPricingTier,
} from "@modules/billing/shared/constants/tiers"
import { TrialRemainingDays } from "@modules/billing/components/TrialRemainingDays"
import { isTrialingPlan } from "@modules/billing/shared/utils"

const rootCss: ThemeCss = theme => ({
  display: `flex`,
  flexWrap: `wrap`,
  marginTop: theme.space[5],

  [theme.mediaQueries.desktop]: {
    marginTop: 0,
  },
})

const gridCss: ThemeCss = theme => ({
  display: `grid`,
  gridTemplateColumns: `auto auto`,
  columnGap: theme.space[8],
  marginBottom: theme.space[6],
})

const colCss: ThemeCss = theme => ({
  display: `grid`,
  rowGap: theme.space[2],
  alignContent: `start`,
})

const labelCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.grey[50],
  textTransform: `uppercase`,
  fontWeight: theme.fontWeights.body,
})

const valueCss: ThemeCss = theme => ({
  fontWeight: theme.fontWeights.semiBold,
  fontSize: theme.fontSizes[1],
  display: `flex`,
  alignItems: `center`,
  color: theme.colors.grey[80],

  svg: {
    marginRight: theme.space[2],
  },
})

const descriptionCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.grey[50],
})

const trialInfoCss: ThemeCss = theme => ({
  gridColumnStart: 1,
  gridColumnEnd: 3,
  paddingTop: theme.space[3],
})

const progressBarCss: ThemeCss = theme => ({
  width: `120px`,
  marginTop: `0.4rem`, // ex
  marginBottom: theme.space[2],
})

const getProgressColor = (theme: Theme, progression: number) => {
  if (progression >= 75) {
    return theme.colors.orange[60]
  }

  return theme.colors.purple[60]
}

export type WorkspacePlanInfoProps = {
  billing: BillingInformation
  platformLimitSummaries: PlatformLimitSummary[]
}

export function WorkspacePlanInfo({
  billing,
  platformLimitSummaries,
  ...rest
}: WorkspacePlanInfoProps) {
  const { plan, status } = billing || {}
  const { buildsTier, hostingTier } = plan || {}
  const buildsInformation = supportedTiers[buildsTier as SupportedPricingTier]
  const hostingInformation = supportedTiers[hostingTier as SupportedPricingTier]

  if (!buildsInformation || !hostingInformation) {
    return null
  }

  const BuildsIcon = buildsInformation.buildsIcon
  const HostingIcon = hostingInformation.hostingIcon

  return (
    <div css={rootCss} {...rest}>
      <div css={gridCss}>
        <div css={colCss}>
          <span css={labelCss}>Builds Plan</span>
          <span css={valueCss}>
            {BuildsIcon && <BuildsIcon size="xsmall" />}{" "}
            {buildsInformation.name}
          </span>
        </div>
        <div css={colCss}>
          <span css={labelCss}>Hosting Plan</span>
          <span css={valueCss}>
            {HostingIcon && <HostingIcon size="xsmall" />}{" "}
            {hostingInformation.name}
          </span>
        </div>
        {status && isTrialingPlan(status) && (
          <div css={trialInfoCss}>
            <TrialRemainingDays days={billing.trialDaysRemaining} />
          </div>
        )}
      </div>

      {platformLimitSummaries.length > 0 && (
        <React.Fragment>
          <Spacer size={8} direction="horizontal" />
          <div css={gridCss}>
            {platformLimitSummaries.map(
              ({ name, workspace, formattedLimit }) => {
                return (
                  <div css={colCss} key={name}>
                    <span css={labelCss}>{name}</span>
                    <div css={progressBarCss}>
                      <ProgressBar
                        value={workspace?.percentageOfLimit || 0}
                        max={100}
                        aria-describedby={name}
                        height={8}
                        getProgressColor={getProgressColor}
                      />
                    </div>
                    <span
                      id={name}
                      css={descriptionCss}
                    >{`${workspace?.formattedCount} / ${formattedLimit}`}</span>
                  </div>
                )
              }
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  )
}
