import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { MdSettings } from "react-icons/md"
import { OrgDetailsTab } from "../constants.js"
import { TabsNav } from "@modules/ui/components/TabsNav"
import {
  getNumberOfSitesInOrganization,
  getPathToOrgDetailsTab,
} from "@modules/organization/shared/utils"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { useOrganizationSitesNameFieldsFragment } from "@modules/organization/fragments.generated"

const orgTabIcons = {
  [OrgDetailsTab.Settings]: MdSettings,
}

const propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tab: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  organizationId: PropTypes.string.isRequired,
  activeTab: PropTypes.string,
}

function OrganizationDetailsTabsNav({ tabs, activeTab, organizationId }) {
  const { trackButtonClicked, trackSegment } = useTracker()
  const { data } = useOrganizationSitesNameFieldsFragment(organizationId)

  const numOfSites = data ? getNumberOfSitesInOrganization(data) : 0

  return (
    <TabsNav>
      <TabsNav.Tabs>
        {tabs.map(({ tab, label }) => {
          const isActiveTab = tab === activeTab
          const Icon = orgTabIcons[tab]
          const TabIcon = Icon ? <Icon css={TabsNav.iconStyle} /> : null
          const SiteNumberChip = tab === OrgDetailsTab.Sites && numOfSites && (
            <TabsNav.Chip data-cy={`org-details-${tab}-tab-sites-total`}>
              {numOfSites}
            </TabsNav.Chip>
          )

          return (
            <TabsNav.Tab key={tab}>
              {isActiveTab ? (
                <TabsNav.NoLink>
                  {label} {TabIcon} {SiteNumberChip}
                </TabsNav.NoLink>
              ) : (
                <TabsNav.Link
                  data-cy={`org-details-${tab}-tab-button`}
                  to={getPathToOrgDetailsTab(organizationId, tab)}
                  onClick={() => {
                    trackSegment({
                      type: SegmentEventType.Track,
                      event: `Navigated to workspace details ${tab} tab`,
                      properties: {
                        location: "Workspace details view",
                      },
                    })
                    trackButtonClicked(label, {
                      organizationId,
                      uiSource: `Organization Settings`,
                    })
                  }}
                >
                  {label} {TabIcon} {SiteNumberChip}
                </TabsNav.Link>
              )}
              {isActiveTab && <Helmet title={label} />}
            </TabsNav.Tab>
          )
        })}
      </TabsNav.Tabs>
    </TabsNav>
  )
}

OrganizationDetailsTabsNav.propTypes = propTypes

export default OrganizationDetailsTabsNav
