import * as React from "react"
import { paymentHistory as paymentHistoryText } from "@modules/locales/default.json"
import { Text, ThemeCss } from "gatsby-interface"
import { FormattedMessage } from "@modules/locales"

const textCss: ThemeCss = theme => ({
  color: theme.colors.grey[80],
})

const supportLinkCss: ThemeCss = theme => ({
  color: theme.colors.blue[80],
  textDecoration: `none`,
})

const introCss: ThemeCss = theme => [
  textCss(theme),
  {
    marginBottom: 0,
  },
]

export type PaymentHistoryProps = {
  className?: string
}

export function PaymentHistory({ className }: PaymentHistoryProps) {
  return (
    <div className={className}>
      <Text size="S" css={introCss}>
        <FormattedMessage<never, "link">
          message={paymentHistoryText.messages.contactForReceipts}
          tags={{
            link: function(content) {
              return (
                <a css={supportLinkCss} href="mailto:support@gatsbyjs.com">
                  {content}
                </a>
              )
            },
          }}
        />
      </Text>
    </div>
  )
}
