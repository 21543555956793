import React from "react"
import { getCardBaseStyles } from "@modules/ui/stylesheets/card"

const siteCardLayoutCss = theme => [
  getCardBaseStyles(theme),
  {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr 1fr 1fr 1fr 1fr",
    gridTemplateAreas: `
    "head"
    "buildStatus"
    "previewStatus"
    "branch"
    "viewDetails"
    `,
    gap: `${theme.space[3]} 0`,

    [theme.mediaQueries.phablet]: {
      gridTemplateColumns: "auto auto 1fr auto",
      gridTemplateRows: "1fr 2fr",
      gap: `0 ${theme.space[7]}`,
      gridTemplateAreas: `
      "head        head          head   viewDetails"
      "buildStatus previewStatus branch viewDetails"`,
      paddingRight: theme.space[7],
    },
  },
]

export function SiteCardLayout({ children, ...props }) {
  return (
    <article {...props} css={siteCardLayoutCss}>
      {children}
    </article>
  )
}

export const siteDetailsLinkCss = theme => ({
  gridArea: "viewDetails",
  textAlign: "center",

  [theme.mediaQueries.phablet]: {
    textAlign: "unset",
  },
})
