import * as React from "react"
import { OrgSettingsSection, OrgSettingsSectionLabel } from "../constants"
import { SettingsDetailsRow } from "@modules/ui/components/SettingsDetailsRow"
import { OrganizationNameForm } from "./OrganizationNameForm"
import { Button, Card, CardHeader, CardSection } from "gatsby-interface"
import { ui as uiText } from "@modules/locales/default.json"
import { MdEdit } from "react-icons/md"

export type OrganizationNameSectionProps = {
  organizationId: string
  organizationName: string
  permissionToEdit?: boolean
}

export function OrganizationNameSection({
  organizationId,
  organizationName,
  permissionToEdit,
}: OrganizationNameSectionProps) {
  const [formVisible, setFormVisible] = React.useState(false)

  return (
    <Card id={OrgSettingsSection.Details}>
      <CardHeader title={OrgSettingsSectionLabel[OrgSettingsSection.Details]}>
        {!formVisible && permissionToEdit && (
          <Button
            onClick={() => {
              return setFormVisible(!formVisible)
            }}
            variant="GHOST"
            rightIcon={<MdEdit />}
          >
            {uiText.actions.edit}
          </Button>
        )}
      </CardHeader>
      <CardSection applyPaddingTop={false}>
        {!formVisible ? (
          <SettingsDetailsRow label="Name" data-testid="org-name-row">
            {organizationName}
          </SettingsDetailsRow>
        ) : (
          <OrganizationNameForm
            organizationId={organizationId}
            organizationName={organizationName}
            onCancel={() => setFormVisible(false)}
            onSuccess={() => setFormVisible(false)}
          />
        )}
      </CardSection>
    </Card>
  )
}
