import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { MdEdit } from "react-icons/md"
import {
  Modal,
  ModalCard,
  Heading,
  Button,
  Card,
  CardHeader,
  CardSection,
  CardDivider,
} from "gatsby-interface"
import { paymentInformation as paymentText } from "@modules/locales/default.json"
import { Resubscribe } from "@modules/billing/components/Modals"
import { UpdatePaymentForm } from "@modules/billing/components/UpdatePaymentForm"
import { CardInformation } from "@modules/billing/components/CardInformation"
import { PaymentHistory } from "@modules/billing/components/PaymentHistory"
import { OrganizationPermissions } from "@modules/organization/permissions"
import { useOrganizationPlanFieldsFragment } from "@modules/billing/shared/fragments.generated"

const InformationSectionCss = theme => ({
  "&.success": {
    background: theme.colors.green[5],
  },

  "&.error": {
    background: theme.colors.red[5],
  },
})

const UpdatePaymentFormCss = theme => ({
  padding: `${theme.space[5]} ${theme.space[7]}`,

  [theme.mediaQueries.desktop]: {
    padding: `${theme.space[8]} ${theme.space[9]}`,
  },
})

function PaymentInformation({
  organizationId,
  billingStatus,
  setResponse,
  res,
}) {
  const [paymentFormVisible, setPaymentFormVisible] = useState(false)
  const [activePaymentInformation, setActivePaymentInformation] = useState()
  const { data } = useOrganizationPlanFieldsFragment(organizationId)
  const {
    name,
    billing: {
      nextBillingDate,
      creditCard: { lastFour, brand },
      plan: { interval, formattedAmount, amount, id },
    },
  } = data || {}

  const cardBrand = !res.creditCard ? brand : res.creditCard.brand
  const cardNumber = !res.creditCard ? lastFour : res.creditCard.lastFour

  return (
    <React.Fragment>
      <Card>
        <Fragment>
          {!paymentFormVisible ? (
            <Fragment>
              <section
                css={InformationSectionCss}
                className={res.success ? `success` : res.err ? `error` : ``}
              >
                <CardHeader title={paymentText.headers.paymentInformation}>
                  <OrganizationPermissions
                    id={organizationId}
                    resource="billing"
                    action="edit"
                  >
                    <Button
                      onClick={() => setPaymentFormVisible(true)}
                      rightIcon={<MdEdit />}
                      variant="GHOST"
                    >
                      {paymentText.actions.updatePayment}
                    </Button>
                  </OrganizationPermissions>
                  {billingStatus === `CANCELED` && (
                    <OrganizationPermissions
                      id={organizationId}
                      resource="billing"
                      action="edit"
                    >
                      <Button
                        onClick={() => {
                          setActivePaymentInformation({
                            name,
                            organizationId,
                            id,
                          })
                        }}
                        rightIcon={<MdEdit />}
                      >
                        {paymentText.actions.updatePayment}
                      </Button>
                    </OrganizationPermissions>
                  )}
                </CardHeader>

                <CardSection applyPaddingTop={false}>
                  <CardInformation
                    cardBrand={cardBrand}
                    cardNumber={cardNumber}
                    interval={interval}
                    amount={amount}
                    formattedAmount={formattedAmount}
                    nextBillingDate={nextBillingDate}
                  />
                </CardSection>
              </section>
              <CardDivider />
              <CardSection>
                <Heading
                  as="h4"
                  css={theme => ({
                    fontSize: theme.fontSizes[3],
                    marginBottom: theme.space[5],
                  })}
                >
                  {paymentText.headers.paymentHistory}
                </Heading>
                <PaymentHistory />
              </CardSection>
            </Fragment>
          ) : (
            <UpdatePaymentForm
              css={UpdatePaymentFormCss}
              name={name}
              organizationId={organizationId}
              id={id}
              setResponse={res => setResponse(res)}
              cancelCallback={() => setPaymentFormVisible(false)}
            />
          )}
        </Fragment>
      </Card>
      {activePaymentInformation && (
        <Modal
          isOpen={Boolean(activePaymentInformation)}
          aria-label={`Do you want to resubscribe?`}
          onDismiss={() => setActivePaymentInformation(null)}
        >
          <ModalCard>
            <Resubscribe
              {...activePaymentInformation}
              closeModal={() => setActivePaymentInformation(null)}
            />
          </ModalCard>
        </Modal>
      )}
    </React.Fragment>
  )
}

PaymentInformation.propTypes = {
  organizationId: PropTypes.string.isRequired,
}

export default PaymentInformation
