import * as React from "react"
import { InvitationEmailResourceCreateInput } from "@modules/graphql/types"

import {
  ContributorsByOrganizationQuery,
  ContributorsByOrganizationDocument,
  useCreateInvitationForResourcesMutation,
} from "@modules/organization/contributors/queries.generated"

import {
  useShowSuccessToast,
  StyledPanelBoilerplate,
  StyledPanelBodySection,
} from "gatsby-interface"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import { InviteMembersForm } from "../InviteMembersForm"
import { addMembers as addMembersText } from "@modules/locales/default.json"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { commonMessageTags, FormattedMessage } from "@modules/locales"
import { memberFieldsToCloudResources } from "../utils"

export type InviteMemberPanelModalProps = {
  isOpen: boolean
  closeModal: () => void
  organizationId: string
}

export function InviteMemberPanelModal({
  isOpen,
  closeModal,
  organizationId,
}: InviteMemberPanelModalProps) {
  const { trackSegment } = useTracker()
  const [inviteMembers, { loading, errorAlert }] = useInviteMembers({
    organizationId,
  })

  return (
    <StyledPanelBoilerplate
      aria-label={addMembersText.headers.addMembersTitle}
      header={addMembersText.headers.addMembersTitle}
      isOpen={isOpen}
      onClose={closeModal}
    >
      {errorAlert && (
        <StyledPanelBodySection css={{ paddingBottom: 0 }}>
          {errorAlert}
        </StyledPanelBodySection>
      )}
      <InviteMembersForm
        organizationId={organizationId}
        onSubmit={invitees => {
          trackSegment({
            type: SegmentEventType.Track,
            event: `Sent invites for new members`,
            properties: {
              location: `Members tab - "Add Members" modal`,
            },
          })

          const invitationsData = invitees.map(invitee => ({
            email: invitee.email,
            resources: memberFieldsToCloudResources(organizationId, invitee),
          }))

          return inviteMembers(invitationsData, () => {
            closeModal()
          })
        }}
        onCancel={closeModal}
        loading={loading}
      />
    </StyledPanelBoilerplate>
  )
}

function useInviteMembers({ organizationId }: { organizationId: string }) {
  const [setError, errorAlert] = useTriggerErrorAlert()
  const showSuccessToast = useShowSuccessToast()
  const [mutate, { loading }] = useCreateInvitationForResourcesMutation()

  const invite = (
    invitations: InvitationEmailResourceCreateInput[],
    onSuccess: () => void
  ) => {
    return mutate({
      variables: {
        invitations,
      },
      update: (cache, data) => {
        const queryInfo = {
          query: ContributorsByOrganizationDocument,
          variables: {
            id: organizationId,
          },
        }
        const contributorsFromCache = cache.readQuery<
          ContributorsByOrganizationQuery
        >(queryInfo)

        const updatedContributors = contributorsFromCache?.contributorsByOrganization?.concat(
          data.data?.createInvitationForResources?.contributors ?? []
        )

        cache.writeQuery({
          ...queryInfo,
          data: { contributorsByOrganization: updatedContributors },
        })
      },
    })
      .then(() => {
        showSuccessToast(
          <FormattedMessage<never, "strong">
            message={addMembersText.messages.memberInvitationSent}
            tags={commonMessageTags}
          />
        )
        onSuccess()
      })
      .catch(err => setError(err))
  }

  return [invite, { loading, errorAlert }] as const
}
