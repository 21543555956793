import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { OrganizationPlanBadge } from "@modules/organization/shared/components/OrganizationPlanBadge"
import Loading from "@modules/ui/components/Loading"
import { setOrgEnvVars } from "@modules/fullStory"
import { isTrialingPlan } from "@modules/billing/shared/utils"
import { EmptyState, Spacer, EmptyStatePrimaryAction } from "gatsby-interface"
import OrganizationDetailsTabsContainer from "./OrganizationDetailsTabsContainer"
import { StandardSingleColumn } from "@modules/ui/layouts/Containers"
import { TrialRemainingDays } from "@modules/billing/components/TrialRemainingDays"
import { organizations as orgText } from "@modules/locales/default.json"
import { DetailsViewHeader } from "@modules/ui/components/DetailsViewHeader"
import { useOrganizationDetailsQuery } from "@modules/organization/queries.generated"
import { MdLockOutline } from "react-icons/md"
import { organizations as organizationsText } from "@modules/locales/default.json"
import { getOrgDetailsTabs } from "@modules/organization/details/utils"
import { useFlags } from "@modules/featureFlags"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { WorkspacePlanInfo } from "@modules/organization/shared/components/WorkspacePlanInfo"
import { usePlatformLimitSummariesQuery } from "@modules/organization/queries.generated"
import { getPathToOrgSettings } from "@modules/organization/shared/utils"
import { getPlatformLimitOveragesMessage } from "@modules/organization/shared/utils/getPlatformLimitOveragesMessage"
import { PlatformLmitOverageNotification } from "@modules/organization/shared/components/PlatformLmitOverageNotification"

const trialHeaderCss = theme => ({
  display: "flex",
  flexDirection: "column",
  flexWrap: `wrap`,
  marginTop: theme.space[3],

  [theme.mediaQueries.phablet]: {
    flexDirection: "row",
  },
})

const trialHorizontalSpacer = theme => ({
  height: theme.space[3],

  [theme.mediaQueries.phablet]: {
    width: theme.space[5],
    height: "unset",
  },
})

const orgPlanInfoCss = theme => ({
  display: `flex`,
  alignItems: `flex-start`,
  flexWrap: `wrap`,
  flexDirection: `column`,

  [theme.mediaQueries.phablet]: {
    flexDirection: `row`,
    alignItems: `center`,
    alignSelf: `flex-end`,
  },
})

const notificationsCss = theme => ({
  display: `grid`,
  marginTop: theme.space[3],
  marginBottom: theme.space[6],
  rowGap: theme.space[6],
})

const workspaceInfoCss = _ => ({
  marginLeft: `auto`,
})

const propTypes = {
  organizationId: PropTypes.string.isRequired,
}

export function OrganizationDetailsView({ organizationId, ...rest }) {
  const { flags } = useFlags()
  const pathToOrgSettings = getPathToOrgSettings(organizationId)

  const { loading, error, data } = useOrganizationDetailsQuery({
    variables: { id: organizationId },
    fetchPolicy: "cache-and-network",
  })

  const {
    data: limitSummariesData,
    loading: limitSummariesDataLoading,
  } = usePlatformLimitSummariesQuery({
    variables: { workspaceId: organizationId },
    fetchPolicy: "cache-and-network",
  })

  const organizationDetails = data?.organizationDetails

  const platformLimitSummaries = (
    limitSummariesData?.platformLimitSummaries || []
  ).filter(Boolean)

  const platformLimitOveragesMessage = getPlatformLimitOveragesMessage(
    platformLimitSummaries
  )

  if (loading || limitSummariesDataLoading) {
    return <Loading delay={1000} message={orgText.messages.loadingDetails} />
  }

  if (error) {
    if (error?.message.includes(`Not Authorised!`)) {
      return (
        <StandardSingleColumn>
          <Spacer size={12} />

          <EmptyState
            graphic={<MdLockOutline />}
            variant="BORDERED"
            heading={organizationsText.headers.noAccess}
            headingAs="h3"
            text={organizationsText.messages.contactTheOwner}
            primaryAction={
              <EmptyStatePrimaryAction to={`/dashboard/sites`}>
                {organizationsText.actions.backToHomepage}
              </EmptyStatePrimaryAction>
            }
          />
        </StandardSingleColumn>
      )
    }

    navigate(`/dashboard/sites`)
    return null
  }

  const { permissions, billing } = organizationDetails || {}

  const hasAccess =
    permissions && permissions.organization && permissions.organization.read

  if (!hasAccess || !organizationId) {
    navigate(`/dashboard/sites`)
    return null
  }

  setOrgEnvVars(
    organizationDetails.name,
    billing.plan.name,
    billing.status,
    billing.trialDaysRemaining,
    permissions.role
  )

  const billingStatus = billing.status
  const planName = billing.plan?.name
  const tabs = getOrgDetailsTabs(flags, permissions.organization)
  const path = rest[`*`]
  const activeTab = tabs.find(({ tab }) => path.includes(tab))

  return (
    <main>
      <Helmet
        titleTemplate={`Gatsby Cloud | ${organizationDetails.name} | %s`}
        defaultTitle={`Gatsby Cloud | ${organizationDetails.name}`}
      />
      <StandardSingleColumn>
        {flags.multiTiersPlans && platformLimitOveragesMessage && (
          <div css={notificationsCss}>
            <PlatformLmitOverageNotification
              orgName={organizationDetails.name}
              message={platformLimitOveragesMessage}
              link={pathToOrgSettings}
            />
          </div>
        )}

        <div css={trialHeaderCss}>
          <DetailsViewHeader eyebrow={orgText.morphemes.organization}>
            {organizationDetails.name}{" "}
            <span css={visuallyHiddenCss}>{activeTab?.label}</span>
          </DetailsViewHeader>

          {flags.multiTiersPlans ? (
            <WorkspacePlanInfo
              billing={billing}
              css={workspaceInfoCss}
              platformLimitSummaries={platformLimitSummaries}
            />
          ) : (
            <React.Fragment>
              <div css={trialHorizontalSpacer} />
              <div css={orgPlanInfoCss}>
                {Boolean(billingStatus && planName) && (
                  <OrganizationPlanBadge
                    billingStatus={billingStatus}
                    planName={planName}
                  />
                )}

                <div css={trialHorizontalSpacer} />

                {isTrialingPlan(billing.status) && (
                  <TrialRemainingDays days={billing.trialDaysRemaining} />
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </StandardSingleColumn>

      <OrganizationDetailsTabsContainer
        organizationId={organizationId}
        orgDetails={organizationDetails}
        tabs={tabs}
        activeTab={activeTab?.tab}
      />
    </main>
  )
}

OrganizationDetailsView.propTypes = propTypes

export default OrganizationDetailsView
