import { OrgDetailsTab, tabsConfig } from "../constants"

/**
 * Exporting a function instead of an array of tabs allows us
 * to have dynamic tabs if we need them (similar to getSiteDetailsTabs)
 */
export function getOrgDetailsTabs(_flags, organizationPermissions) {
  const tabs = [
    tabsConfig[OrgDetailsTab.Sites],
    tabsConfig[OrgDetailsTab.Members],
  ]

  if (organizationPermissions?.read) {
    tabs.push(tabsConfig[OrgDetailsTab.Settings])
  }

  return tabs
}
