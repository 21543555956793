export const listCss = theme => ({
  listStyle: `none`,
  margin: `${theme.space[5]} 0 0 0`,
  padding: `0`,
  display: `grid`,
  gridGap: theme.space[5],
})

export const listItemCss = theme => ({
  color: theme.colors.grey[60],
  fontFamily: theme.fonts.heading,
  fontSize: theme.fontSizes[3],
  lineHeight: `normal`,
  margin: `0 ${theme.space[5]} 0 0`,
  width: `100%`,

  [theme.mediaQueries.tablet]: {
    width: `inherit`,
  },
})
