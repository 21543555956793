import React from "react"
import {
  PageWithTabsContentSection,
  StandardSingleColumn,
} from "@modules/ui/layouts/Containers"
import MembersTab from "@modules/organization/contributors/components/MembersTab"
import { useTracker } from "@modules/analytics"

export default function OrganizationMembersPane({
  organizationId,
  organizationDetails,
}) {
  const { name, billing, permissions } = organizationDetails
  const { trackPageViewed } = useTracker()

  React.useEffect(() => {
    trackPageViewed(`Organization Members`)
  }, [])

  return (
    <PageWithTabsContentSection>
      <StandardSingleColumn>
        <MembersTab
          billingStatus={billing && billing.status}
          organizationName={name}
          organizationId={organizationId}
          permissionToInvite={permissions.contributor.invite}
          permissionToRead={permissions.contributor.read}
          permissionToDelete={permissions.contributor.delete}
        />
      </StandardSingleColumn>
    </PageWithTabsContentSection>
  )
}
