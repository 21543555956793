import * as React from "react"
import { orgMembers as orgMembersText } from "@modules/locales/default.json"
import { InviteMemberPanelModal } from "@modules/organization/contributors/components/MemberActionModals"
import { Button } from "gatsby-interface"
import { MdAdd } from "react-icons/md"
import { useTracker, SegmentEventType } from "@modules/analytics"

export type AddMemberActionProps = {
  organizationId: string
  className?: string
}

function AddMemberAction({ organizationId, className }: AddMemberActionProps) {
  const { trackSegment } = useTracker()
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = React.useState(false)
  return (
    <React.Fragment>
      <Button
        size={`M`}
        onClick={() => {
          trackSegment({
            type: SegmentEventType.Track,
            event: `Activated "Add Members" button`,
            properties: {
              location: "Members tab",
            },
          })
          setIsAddMemberModalOpen(true)
        }}
        rightIcon={<MdAdd />}
        className={className}
      >
        {orgMembersText.actions.addMembers}
      </Button>
      <InviteMemberPanelModal
        organizationId={organizationId}
        isOpen={isAddMemberModalOpen}
        closeModal={() => setIsAddMemberModalOpen(false)}
      />
    </React.Fragment>
  )
}

export default AddMemberAction
