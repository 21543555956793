import * as React from "react"
import {
  useShowSuccessToast,
  Button,
  Modal,
  ModalCard,
  StyledModal,
  StyledModalHeader,
  StyledModalBody,
  StyledModalActions,
  Text,
} from "gatsby-interface"
import { ModalMessage, DefaultError } from "@modules/modal"
import {
  manageContributors as text,
  ui as uiText,
} from "@modules/locales/default.json"
import { useTransferOrganizationOwnershipMutation } from "@modules/organization/mutations.generated"
import { ContributorsByOrganizationDocument } from "@modules/organization/contributors/queries.generated"
import { OrganizationDetailsDocument } from "@modules/organization/queries.generated"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { FormattedMessage, commonMessageTags } from "@modules/locales"
import { StyledForm } from "@modules/ui/components/Primitives"

export type TransferOrgOwnershipModalProps = {
  closeModal: () => void
  email: string
  organizationId: string
  contributorId: string
}

function TransferOrgOwnershipModal({
  closeModal,
  email,
  organizationId,
  contributorId,
}: TransferOrgOwnershipModalProps) {
  const { trackSegment } = useTracker()
  const showSuccessToast = useShowSuccessToast()
  const [error, setError] = React.useState(null)
  const [mutate, { loading }] = useTransferOrganizationOwnershipMutation()

  return (
    <StyledModal variant="WARNING">
      <StyledModalHeader onCloseButtonClick={closeModal}>
        {text.headers.transferOrgQuestion}
      </StyledModalHeader>
      <StyledModalBody>
        <ModalMessage>
          <Text>
            <FormattedMessage<"email", "strong">
              message={text.messages.transferOrgConf}
              values={{ email }}
              tags={commonMessageTags}
            />
          </Text>
          <Text css={{ marginBottom: 0 }}>
            {text.messages.transferOrgEffects}
          </Text>
        </ModalMessage>
        <StyledForm
          onSubmit={e => {
            e.preventDefault()
            trackSegment({
              type: SegmentEventType.Track,
              event: `Submitted org ownership transfer to different member`,
              properties: {
                location: `Members tab - "Transfer ownership" modal`,
              },
            })
            mutate({
              variables: { organizationId, targetUserId: contributorId },
              refetchQueries: () => [
                {
                  query: OrganizationDetailsDocument,
                  variables: { id: organizationId },
                },
                {
                  query: ContributorsByOrganizationDocument,
                  variables: { id: organizationId },
                },
              ],
            })
              .then(() => {
                closeModal()
                showSuccessToast(text.messages.orgOwnershipTransferred)
              })
              .catch(err => setError(err.message))
          }}
        >
          <StyledModalActions>
            <Button
              variant={`SECONDARY`}
              tone={`NEUTRAL`}
              type="button"
              onClick={() => {
                trackSegment({
                  type: SegmentEventType.Track,
                  event: `Cancelled transferring ownership to different member`,
                  properties: {
                    location: `Members tab - "Transfer ownership" modal`,
                  },
                })
                closeModal()
              }}
            >
              {uiText.actions.cancel}
            </Button>
            <Button loading={loading} tone={`DANGER`} type="submit">
              {text.actions.transfer}
            </Button>
          </StyledModalActions>
        </StyledForm>
      </StyledModalBody>

      <Modal
        data-testid="transfer-ownership-modal-error"
        isOpen={Boolean(error)}
        aria-label={`Something went wrong.`}
        onDismiss={() => setError(null)}
        type="error"
      >
        <ModalCard>
          <DefaultError
            errMsg={error || ``}
            closeModal={() => setError(null)}
          />
        </ModalCard>
      </Modal>
    </StyledModal>
  )
}

export { TransferOrgOwnershipModal }
