import * as React from "react"
import { MutationUpdaterFn } from "apollo-client"
import { useShowSuccessToast } from "gatsby-interface"
import { FormattedMessage, commonMessageTags } from "@modules/locales"
import { organizationStatus as organizationStatusText } from "@modules/locales/default.json"
import { OrganizationStatus } from "@modules/graphql/types"
import {
  SetOrganizationActiveMutation,
  useSetOrganizationActiveMutation,
} from "../queries.generated"
import {
  OrganizationStatusFieldsFragment,
  OrganizationStatusFieldsFragmentDoc,
} from "../fragments.generated"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"

export function useSetOrganizationActive(
  organizationId: string,
  organizationName: string
) {
  const [mutate, ...rest] = useSetOrganizationActiveMutation()
  const [setError, errorAlert] = useTriggerErrorAlert()
  const showSuccessToast = useShowSuccessToast()

  const setOrganizationActive = () =>
    mutate({
      variables: { id: organizationId },
      update: updateOrgActiveCache(organizationId),
    })
      .then(() =>
        showSuccessToast(
          <FormattedMessage<"organizationName", "strong">
            message={organizationStatusText.messages.reactivated}
            values={{
              organizationName,
            }}
            tags={commonMessageTags}
          />
        )
      )
      .catch(error => setError(error))

  return [setOrganizationActive, ...rest, errorAlert] as const
}

function updateOrgActiveCache(
  organizationId: string
): MutationUpdaterFn<SetOrganizationActiveMutation> {
  return (cache, { data }) => {
    if (!data?.setOrganizationActive?.success) {
      return
    }
    cache.writeFragment<OrganizationStatusFieldsFragment>({
      id: `Organization:${organizationId}`,
      fragment: OrganizationStatusFieldsFragmentDoc,
      data: {
        status: OrganizationStatus.Active,
      },
    })
  }
}
