import React from "react"
import { Notification } from "gatsby-interface"

export default function PlanChangeNotification({
  currentPlanName,
  requestedPlanName,
  onDismiss,
}) {
  return (
    <Notification
      tone="SUCCESS"
      content={
        <React.Fragment>
          {requestedPlanName
            ? `Successfully sent request to change plan to `
            : `Successfully changed plan to `}
          <strong
            css={theme => ({
              marginLeft: theme.space[2],
              marginRight: theme.space[2],
            })}
          >
            {requestedPlanName ? requestedPlanName : currentPlanName}!
          </strong>
        </React.Fragment>
      }
      onDismissButtonClick={onDismiss}
    />
  )
}
