// THIS IS A GENERATED FILE
import * as Types from "../graphql/types"

import gql from "graphql-tag"
import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/react-hooks"

export type InviteContributorsToResourceMutationVariables = Types.Exact<{
  id: Types.Scalars["UUID"]
  emails: Array<Types.Scalars["String"]>
  resourceType: Types.CloudResourceType
}>

export type InviteContributorsToResourceMutation = {
  __typename?: "Mutation"
} & {
  inviteContributorsToResource?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: "Contributor" } & Pick<
          Types.Contributor,
          "id" | "name" | "email" | "avatarUrl" | "contributorRole"
        >
      >
    >
  >
}

export type DeleteContributorFromOrganizationMutationVariables = Types.Exact<{
  organizationId: Types.Scalars["UUID"]
  contributorId: Types.Scalars["UUID"]
}>

export type DeleteContributorFromOrganizationMutation = {
  __typename?: "Mutation"
} & {
  deleteContributorFromOrganization?: Types.Maybe<
    { __typename?: "MutationResult" } & Pick<
      Types.MutationResult,
      "success" | "message"
    >
  >
}

export type ResendInvitationMutationVariables = Types.Exact<{
  id: Types.Scalars["UUID"]
}>

export type ResendInvitationMutation = { __typename?: "Mutation" } & {
  resendInvitation?: Types.Maybe<
    { __typename?: "MutationResult" } & Pick<
      Types.MutationResult,
      "success" | "message"
    >
  >
}

export type RevokeInvitationMutationVariables = Types.Exact<{
  id: Types.Scalars["UUID"]
}>

export type RevokeInvitationMutation = { __typename?: "Mutation" } & {
  revokeInvitation?: Types.Maybe<
    { __typename?: "MutationResult" } & Pick<
      Types.MutationResult,
      "success" | "message"
    >
  >
}

export type TransferOrganizationOwnershipMutationVariables = Types.Exact<{
  organizationId: Types.Scalars["UUID"]
  targetUserId: Types.Scalars["UUID"]
}>

export type TransferOrganizationOwnershipMutation = {
  __typename?: "Mutation"
} & {
  transferOrganizationOwnership?: Types.Maybe<
    { __typename?: "MutationResult" } & Pick<
      Types.MutationResult,
      "success" | "message"
    >
  >
}

export const InviteContributorsToResourceDocument = gql`
  mutation inviteContributorsToResource(
    $id: UUID!
    $emails: [String!]!
    $resourceType: CloudResourceType!
  ) {
    inviteContributorsToResource(
      id: $id
      emails: $emails
      resourceType: $resourceType
    ) {
      id
      name
      email
      avatarUrl
      contributorRole
    }
  }
`
export type InviteContributorsToResourceMutationFn = ApolloReactCommon.MutationFunction<
  InviteContributorsToResourceMutation,
  InviteContributorsToResourceMutationVariables
>

/**
 * __useInviteContributorsToResourceMutation__
 *
 * To run a mutation, you first call `useInviteContributorsToResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteContributorsToResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteContributorsToResourceMutation, { data, loading, error }] = useInviteContributorsToResourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      emails: // value for 'emails'
 *      resourceType: // value for 'resourceType'
 *   },
 * });
 */
export function useInviteContributorsToResourceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    InviteContributorsToResourceMutation,
    InviteContributorsToResourceMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    InviteContributorsToResourceMutation,
    InviteContributorsToResourceMutationVariables
  >(InviteContributorsToResourceDocument, baseOptions)
}
export type InviteContributorsToResourceMutationHookResult = ReturnType<
  typeof useInviteContributorsToResourceMutation
>
export type InviteContributorsToResourceMutationResult = ApolloReactCommon.MutationResult<
  InviteContributorsToResourceMutation
>
export type InviteContributorsToResourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  InviteContributorsToResourceMutation,
  InviteContributorsToResourceMutationVariables
>
export const DeleteContributorFromOrganizationDocument = gql`
  mutation deleteContributorFromOrganization(
    $organizationId: UUID!
    $contributorId: UUID!
  ) {
    deleteContributorFromOrganization(
      organizationId: $organizationId
      contributorId: $contributorId
    ) {
      success
      message
    }
  }
`
export type DeleteContributorFromOrganizationMutationFn = ApolloReactCommon.MutationFunction<
  DeleteContributorFromOrganizationMutation,
  DeleteContributorFromOrganizationMutationVariables
>

/**
 * __useDeleteContributorFromOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteContributorFromOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContributorFromOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContributorFromOrganizationMutation, { data, loading, error }] = useDeleteContributorFromOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      contributorId: // value for 'contributorId'
 *   },
 * });
 */
export function useDeleteContributorFromOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteContributorFromOrganizationMutation,
    DeleteContributorFromOrganizationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteContributorFromOrganizationMutation,
    DeleteContributorFromOrganizationMutationVariables
  >(DeleteContributorFromOrganizationDocument, baseOptions)
}
export type DeleteContributorFromOrganizationMutationHookResult = ReturnType<
  typeof useDeleteContributorFromOrganizationMutation
>
export type DeleteContributorFromOrganizationMutationResult = ApolloReactCommon.MutationResult<
  DeleteContributorFromOrganizationMutation
>
export type DeleteContributorFromOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteContributorFromOrganizationMutation,
  DeleteContributorFromOrganizationMutationVariables
>
export const ResendInvitationDocument = gql`
  mutation resendInvitation($id: UUID!) {
    resendInvitation(id: $id) {
      success
      message
    }
  }
`
export type ResendInvitationMutationFn = ApolloReactCommon.MutationFunction<
  ResendInvitationMutation,
  ResendInvitationMutationVariables
>

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendInvitationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResendInvitationMutation,
    ResendInvitationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ResendInvitationMutation,
    ResendInvitationMutationVariables
  >(ResendInvitationDocument, baseOptions)
}
export type ResendInvitationMutationHookResult = ReturnType<
  typeof useResendInvitationMutation
>
export type ResendInvitationMutationResult = ApolloReactCommon.MutationResult<
  ResendInvitationMutation
>
export type ResendInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResendInvitationMutation,
  ResendInvitationMutationVariables
>
export const RevokeInvitationDocument = gql`
  mutation revokeInvitation($id: UUID!) {
    revokeInvitation(id: $id) {
      success
      message
    }
  }
`
export type RevokeInvitationMutationFn = ApolloReactCommon.MutationFunction<
  RevokeInvitationMutation,
  RevokeInvitationMutationVariables
>

/**
 * __useRevokeInvitationMutation__
 *
 * To run a mutation, you first call `useRevokeInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeInvitationMutation, { data, loading, error }] = useRevokeInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRevokeInvitationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RevokeInvitationMutation,
    RevokeInvitationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RevokeInvitationMutation,
    RevokeInvitationMutationVariables
  >(RevokeInvitationDocument, baseOptions)
}
export type RevokeInvitationMutationHookResult = ReturnType<
  typeof useRevokeInvitationMutation
>
export type RevokeInvitationMutationResult = ApolloReactCommon.MutationResult<
  RevokeInvitationMutation
>
export type RevokeInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RevokeInvitationMutation,
  RevokeInvitationMutationVariables
>
export const TransferOrganizationOwnershipDocument = gql`
  mutation transferOrganizationOwnership(
    $organizationId: UUID!
    $targetUserId: UUID!
  ) {
    transferOrganizationOwnership(
      organizationId: $organizationId
      targetUserId: $targetUserId
    ) {
      success
      message
    }
  }
`
export type TransferOrganizationOwnershipMutationFn = ApolloReactCommon.MutationFunction<
  TransferOrganizationOwnershipMutation,
  TransferOrganizationOwnershipMutationVariables
>

/**
 * __useTransferOrganizationOwnershipMutation__
 *
 * To run a mutation, you first call `useTransferOrganizationOwnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferOrganizationOwnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferOrganizationOwnershipMutation, { data, loading, error }] = useTransferOrganizationOwnershipMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      targetUserId: // value for 'targetUserId'
 *   },
 * });
 */
export function useTransferOrganizationOwnershipMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TransferOrganizationOwnershipMutation,
    TransferOrganizationOwnershipMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    TransferOrganizationOwnershipMutation,
    TransferOrganizationOwnershipMutationVariables
  >(TransferOrganizationOwnershipDocument, baseOptions)
}
export type TransferOrganizationOwnershipMutationHookResult = ReturnType<
  typeof useTransferOrganizationOwnershipMutation
>
export type TransferOrganizationOwnershipMutationResult = ApolloReactCommon.MutationResult<
  TransferOrganizationOwnershipMutation
>
export type TransferOrganizationOwnershipMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TransferOrganizationOwnershipMutation,
  TransferOrganizationOwnershipMutationVariables
>
