import React from "react"
import PropTypes from "prop-types"
import { Notification } from "gatsby-interface"
import { getPathToOrgSettings } from "@modules/organization/shared/utils"
import { useTracker } from "@modules/analytics"
import { organizationStatus as text } from "@modules/locales/default.json"

function IdleStatus(props) {
  const {
    status,
    orgId,
    styling = `card`,
    showLink,
    className,
    uiSource,
  } = props
  const { trackButtonClicked } = useTracker()

  if (!status || !orgId) {
    return null
  }

  const pathToSettings = getPathToOrgSettings(orgId)

  return (
    <Notification
      tone="WARNING"
      content={<strong>{text.messages.orgIsIdle}</strong>}
      linkText={showLink ? text.actions.reactivate : null}
      linkUrl={`${pathToSettings}/general#status`}
      onLinkClick={() => {
        trackButtonClicked("Reactivate this organization", {
          organizationId: orgId,
          uiSource,
        })
      }}
      variant={styling === `section` ? `SECONDARY` : `PRIMARY`}
      className={className}
    />
  )
}

IdleStatus.propTypes = {
  orgId: PropTypes.string,
  status: PropTypes.string.isRequired,
  billing: PropTypes.object,
  styling: PropTypes.oneOf([`card`, `section`]),
  showLink: PropTypes.bool,
  className: PropTypes.string,
  uiSource: PropTypes.string,
}

export default IdleStatus
