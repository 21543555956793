import * as React from "react"
import { Avatar, Heading } from "gatsby-interface"
import { ThemeCss } from "gatsby-interface"
import {
  Contributor as ContributorType,
  ContributorRole,
} from "@modules/graphql/types"

const memberDetailsContainerCss: ThemeCss = _theme => ({
  display: `flex`,
  width: `100%`,
  alignItems: `center`,
})

const memberInfoCss: ThemeCss = _theme => ({
  display: `flex`,
  flexDirection: `column`,
})

export type MemberInfoProps = {
  children: React.ReactNode
}

function MemberInfo({ children }: MemberInfoProps) {
  return <div css={memberInfoCss}>{children}</div>
}

export type MemberDetailsProps = {
  member: ContributorType
}

function MemberDetails({ member }: MemberDetailsProps) {
  const { name, email, contributorRole } = member
  return (
    <div css={memberDetailsContainerCss}>
      <Avatar
        css={theme => ({
          marginRight: theme.space[5],
        })}
        src={member.avatarUrl ?? ``}
        label={
          member.avatarUrl ? `${member.name} avatar` : `Default user avatar`
        }
        size={`L`}
      />
      {contributorRole === ContributorRole.Invited ? (
        <MemberInfo>
          <Heading
            as="span"
            css={theme => ({
              fontStyle: `italic`,
              fontSize: theme.fontSizes[2],
            })}
          >
            Pending Invite
          </Heading>
          <span
            data-cy="member-email"
            css={theme => ({ fontSize: theme.fontSizes[1] })}
          >
            {email}
          </span>
        </MemberInfo>
      ) : (
        <MemberInfo>
          <Heading
            data-cy="member-name"
            as="span"
            css={theme => ({ fontSize: theme.fontSizes[2] })}
          >
            {name}
          </Heading>
          <span
            data-cy="member-email"
            css={theme => ({ fontSize: theme.fontSizes[1] })}
          >
            {email}
          </span>
        </MemberInfo>
      )}
    </div>
  )
}

export default MemberDetails
