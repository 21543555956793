import * as React from "react"
import { MdArrowForward } from "react-icons/md"
import { Button, InputConnectedField, ThemeCss } from "gatsby-interface"
import Form from "../../../form/components/Form"
import * as Yup from "yup"
import { FormikConfig } from "formik"
import { useUpdateOrganizationNameMutation } from "../queries.generated"
import {
  OrganizationNameFieldsFragment,
  OrganizationNameFieldsFragmentDoc,
} from "../fragments.generated"
import {
  editOrganization as editText,
  ui as uiText,
} from "@modules/locales/default.json"

const formCss: ThemeCss = theme => ({
  display: `grid`,
  gridGap: theme.space[5],
})

type NameFormValues = {
  name: string
}

const validationSchema = Yup.object<NameFormValues>().shape({
  name: Yup.string().required(`Enter a space name`),
})

export type OrganizationNameFormProps = {
  organizationId: string
  organizationName: string
  onSuccess: () => void
  onCancel: () => void
}

export function OrganizationNameForm({
  organizationId,
  organizationName,
  onSuccess,
  onCancel,
}: OrganizationNameFormProps) {
  const [mutate, { loading }] = useUpdateOrganizationNameMutation()

  const handleSubmit: FormikConfig<NameFormValues>["onSubmit"] = values => {
    mutate({
      variables: {
        organizationId,
        name: values.name,
      },
      update: (client, data) => {
        if (!data?.data?.updateOrganizationName?.success) {
          return
        }

        client.writeFragment<OrganizationNameFieldsFragment>({
          id: `Organization:${organizationId}`,
          fragment: OrganizationNameFieldsFragmentDoc,
          data: {
            name: values.name,
            __typename: `Organization`,
          },
        })
      },
    }).then(() => onSuccess())
  }

  return (
    <Form<NameFormValues>
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={{ name: organizationName }}
    >
      <Form.FormElement css={formCss}>
        <InputConnectedField
          name="name"
          label={editText.labels.orgName}
          required
          layout="horizontal"
        />
        <Form.Actions>
          <Button
            variant="SECONDARY"
            tone="NEUTRAL"
            type={`reset`}
            onClick={() => {
              onCancel()
            }}
          >
            {uiText.actions.cancel}
          </Button>
          <Button
            data-cy="save-org-name-button"
            type="submit"
            loading={loading}
            rightIcon={<MdArrowForward />}
          >
            {uiText.actions.save}
          </Button>
        </Form.Actions>
      </Form.FormElement>
    </Form>
  )
}
