import * as React from "react"
import MemberDetails from "./MemberDetails"
import MemberManagement from "./MemberManagement"
import { ThemeCss } from "gatsby-interface"
import { Contributor as ContributorType } from "@modules/graphql/types"

const memberListCss: ThemeCss = _theme => ({
  margin: 0,
})

export type MemberListProps = {
  children: React.ReactNode
}

export function MemberList({ children }: MemberListProps) {
  return <ul css={memberListCss}>{children}</ul>
}

const memberCardCss: ThemeCss = theme => [
  theme.cardStyles.frame,
  {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `space-between`,
    padding: theme.space[7],

    [theme.mediaQueries.phablet]: [
      theme.cardStyles.space.L,
      {
        flexDirection: `row`,
      },
    ],
  },
]

const managementCss: ThemeCss = theme => ({
  marginTop: theme.space[3],
  [theme.mediaQueries.phablet]: {
    marginTop: 0,
  },
})

export type MemberCardProps = {
  member: ContributorType
  organizationId: string
  permissionToInvite: boolean
  permissionToRead: boolean
  permissionToDelete: boolean
}

export function MemberCard({
  member,
  organizationId,
  permissionToInvite,
  permissionToRead,
  permissionToDelete,
}: MemberCardProps) {
  return (
    <li css={memberCardCss}>
      <MemberDetails member={member} />
      <MemberManagement
        member={member}
        organizationId={organizationId}
        permissionToInvite={permissionToInvite}
        permissionToRead={permissionToRead}
        permissionToDelete={permissionToDelete}
        css={managementCss}
      />
    </li>
  )
}
