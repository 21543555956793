import React, { forwardRef } from "react"

import { getPathToSiteDetails } from "@modules/site/details/utils"
import ForwardLink from "@modules/ui/components/ForwardLink"
import { useTracker, GAEventType } from "@modules/analytics"

const SiteDetailsLink = forwardRef(function SiteDetailsLink(
  { siteId, organizationId, className, variant = `SECONDARY` },
  ref
) {
  const { trackGoogleAnalytics } = useTracker()
  const pathToDetails = getPathToSiteDetails(siteId, organizationId)

  return (
    <div className={className}>
      <ForwardLink
        aria-label="Site Details"
        to={pathToDetails}
        variant={variant}
        ref={ref}
        onClick={() => {
          trackGoogleAnalytics({
            eventType: GAEventType.Click,
            category: `Product Engagement - Dashboard`,
            label: {
              loc: `in-page`,
              text: `view details`,
            },
          })
        }}
      >
        View details
      </ForwardLink>
    </div>
  )
})

export default SiteDetailsLink
