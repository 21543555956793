import * as React from "react"
import { Button, Card, CardHeader, CardSection, Text } from "gatsby-interface"
import { MdLightbulbOutline } from "react-icons/md"
import { OrganizationStatus as OrganizationStatusEnum } from "@modules/graphql/types"
import {
  OrgSettingsSection,
  OrgSettingsSectionLabel,
} from "@modules/organization/Settings/constants"
import { organizationStatus as text } from "@modules/locales/default.json"
import { useSetOrganizationActive } from "./OrganizationStatus.helpers"

export type OrganizationStatusProps = {
  organizationId: string
  organizationName: string
  organizationStatus: OrganizationStatusEnum
}

export function OrganizationStatus({
  organizationId,
  organizationName,
  organizationStatus,
}: OrganizationStatusProps) {
  const [
    setOrganizationActive,
    { loading },
    errorAlert,
  ] = useSetOrganizationActive(organizationId, organizationName)

  return (
    <Card id={OrgSettingsSection.Status}>
      <CardHeader title={OrgSettingsSectionLabel[OrgSettingsSection.Status]}>
        {organizationStatus === OrganizationStatusEnum.Idle && (
          <Button
            loading={loading}
            variant={`GHOST`}
            onClick={setOrganizationActive}
            rightIcon={<MdLightbulbOutline />}
          >
            {text.actions.reactivateShort}
          </Button>
        )}
      </CardHeader>
      <CardSection applyPaddingTop={false}>
        {errorAlert}
        <Text
          size="S"
          css={{
            margin: "0",
          }}
        >
          {organizationStatus === OrganizationStatusEnum.Idle &&
            text.messages.idleStatusInfo}

          {organizationStatus === OrganizationStatusEnum.Active &&
            text.messages.activeStatusInfo}

          {organizationStatus === OrganizationStatusEnum.Overage &&
            text.messages.overageStatusInfo}
        </Text>
      </CardSection>
    </Card>
  )
}
