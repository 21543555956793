import * as React from "react"
import { useContributorsByOrganizationQuery } from "@modules/organization/contributors/queries.generated"
import { Content } from "@modules/ui/layouts/ColumnLayout"
import { ThemeCss, Link, EmptyState } from "gatsby-interface"
import { MdHelpOutline } from "react-icons/md"
import Loading from "@modules/ui/components/Loading"
import { orgMembers as orgMembersText } from "@modules/locales/default.json"
import {
  MemberList,
  MemberCard,
} from "@modules/organization/contributors/components/MemberCard"
import MemberListFilter from "@modules/organization/contributors/components/MemberListFilter"
import AddMemberAction from "@modules/organization/contributors/components/AddMemberAction"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"
import { SubscriptionStatus } from "@modules/graphql/types"

const addMemberActionCss: ThemeCss = theme => ({
  marginLeft: theme.space[2],
  flexShrink: 0,
  [theme.mediaQueries.tablet]: {
    marginLeft: theme.space[4],
  },
})

const documentationLinkCss: ThemeCss = _theme => ({
  display: `inline`,
})

const documentationIconCss: ThemeCss = theme => ({
  verticalAlign: `middle`,
  marginRight: theme.space[2],
})

export type MembersTabProps = {
  organizationId: string
  permissionToInvite: boolean
  permissionToRead: boolean
  permissionToDelete: boolean
  billingStatus: SubscriptionStatus
}

export default function MembersTab({
  billingStatus,
  organizationId,
  permissionToInvite,
  permissionToRead,
  permissionToDelete,
}: MembersTabProps) {
  const [memberSearchTerm, updateMemberSearchTerm] = React.useState(``)

  const { loading, error, data } = useContributorsByOrganizationQuery({
    variables: {
      id: organizationId,
    },
    fetchPolicy: `cache-and-network`,
    skip: !permissionToRead,
  })

  if (!permissionToRead) {
    return (
      <EmptyState
        variant="BORDERED"
        heading={orgMembersText.headers.noViewPermissions}
        headingAs="h3"
        text={orgMembersText.messages.noViewPermissionsDescription}
        secondaryAction={
          <Link
            css={documentationLinkCss}
            target="_blank"
            href={`${window.location.origin}/docs/managing-contributors-and-permissioning/`}
          >
            <MdHelpOutline css={documentationIconCss} />
            {orgMembersText.labels.docsLink}
          </Link>
        }
      />
    )
  }

  if (loading)
    return (
      <Loading
        delay={1000}
        message={orgMembersText.messages.loadingOrgMembers}
        bufferSize="none"
      />
    )

  if (error) return <ErrorAlert>{error.message}</ErrorAlert>

  const contributorsByOrganization =
    (data && data.contributorsByOrganization) || []

  const filteredMembers = memberSearchTerm
    ? contributorsByOrganization.filter(
        contributor =>
          contributor?.name
            ?.toLowerCase()
            .includes(memberSearchTerm.toLowerCase()) ||
          contributor?.email
            ?.toLowerCase()
            .includes(memberSearchTerm.toLowerCase())
      )
    : contributorsByOrganization

  return (
    <Content>
      {billingStatus !== SubscriptionStatus.Free && (
        <div css={{ display: `flex` }}>
          <MemberListFilter
            aria-label={orgMembersText.labels.searchMembers}
            placeholder={orgMembersText.labels.searchMembers}
            value={memberSearchTerm}
            onChange={e => {
              updateMemberSearchTerm(e.target.value)
            }}
          />
          {permissionToInvite && (
            <AddMemberAction
              organizationId={organizationId}
              css={addMemberActionCss}
            />
          )}
        </div>
      )}

      <MemberList>
        {filteredMembers.map(member => {
          return (
            <MemberCard
              key={member.id}
              member={member}
              organizationId={organizationId}
              permissionToInvite={permissionToInvite}
              permissionToRead={permissionToRead}
              permissionToDelete={permissionToDelete}
            />
          )
        })}
        {billingStatus === SubscriptionStatus.Free && (
          <EmptyState
            variant="BORDERED"
            heading={orgMembersText.headers.noPlan}
            headingAs="h3"
            text={orgMembersText.messages.noPlan}
            secondaryAction={
              <Link
                css={documentationLinkCss}
                href={`${window.location.origin}/dashboard/pricing/${organizationId}`}
                target="_self"
              >
                {orgMembersText.labels.noPlan}
              </Link>
            }
          />
        )}
      </MemberList>
    </Content>
  )
}
