import getSideNavOptions from "@modules/layout/utils/getSideNavOptions"
import { getPathToOrgSettings } from "../../shared/utils"
import {
  OrgSettingsSectionLabel,
  OrgSettingsSection,
  orgSettings,
} from "../constants"
import { SubscriptionStatus } from "@modules/graphql/types"

export function useOrgSettingsNavigation(
  organizationId,
  location,
  organizationDetails
) {
  // Only show status section if user is free tier or trialling
  const billingStatus =
    organizationDetails &&
    organizationDetails.billing &&
    organizationDetails.billing.status
  const isFreeOrTrialing = [
    SubscriptionStatus.Free,
    SubscriptionStatus.Trialing,
  ].includes(billingStatus)

  const filterWith = id => {
    if (id === OrgSettingsSection.Status && !isFreeOrTrialing) {
      return false
    }

    return true
  }
  return getSideNavOptions({
    sections: orgSettings,
    sectionLabels: OrgSettingsSectionLabel,
    getLink: sectionId =>
      `${getPathToOrgSettings(organizationId)}/${sectionId}`,
    location,
    filterWith,
  })
}
