import * as React from "react"
import * as Yup from "yup"
import { useContributorsByOrganizationQuery } from "@modules/organization/contributors/queries.generated"
import {
  MemberFieldsValues,
  MemberFieldsGroupProps,
  createMemberFieldsValidationSchema,
} from "./MemberFieldsGroup"

type FormValues = {
  members: MemberFieldsValues[]
}

export function useMembersValidation(organizationId: string) {
  type FormErrors = {
    [k: string]: NonNullable<MemberFieldsGroupProps["errors"]>
  }

  const validationSchemaRef = React.useRef(createValidationSchema([]))

  useContributorsByOrganizationQuery({
    variables: {
      id: organizationId,
    },
    fetchPolicy: `cache-first`,
    onCompleted: ({ contributorsByOrganization }) => {
      validationSchemaRef.current = createValidationSchema(
        (contributorsByOrganization ?? []).map(member => member.email)
      )
    },
  })

  const [errors, setErrors] = React.useState<FormErrors>({})

  const validateForm = (formState: FormValues): boolean => {
    const nextErrors: FormErrors = {}
    let isValid = true

    try {
      validationSchemaRef.current.validateSync(formState, { abortEarly: false })
    } catch (e) {
      isValid = false
      const validationError = e as Yup.ValidationError

      validationError.inner.forEach(error => {
        const [memberPath, fieldPath] = error.path.split(".")
        if (!nextErrors[memberPath]) {
          nextErrors[memberPath] = {
            email: null,
            role: null,
            accessAllSites: null,
            sites: null,
          }
        }
        nextErrors[memberPath][fieldPath as keyof MemberFieldsValues] =
          error.message
      })
    }
    setErrors(nextErrors)

    return isValid
  }

  return [validateForm, errors] as const
}

function createValidationSchema(existingEmails: string[]) {
  const memberSchema = createMemberFieldsValidationSchema({
    includeEmail: true,
    existingEmails,
  })

  return Yup.object().shape<FormValues>({
    members: Yup.array().of(memberSchema),
  })
}
