import * as React from "react"
import { ThemeCss, StyledInput } from "gatsby-interface"
import { MdSearch } from "react-icons/md"

const filterInputCss: ThemeCss = theme => ({
  width: `100%`,
  padding: `0 ${theme.space[4]} 0 ${theme.space[8]}`,
  flexGrow: 1,
  zIndex: 0,
})

export const searchIconCss: ThemeCss = theme => ({
  position: `absolute`,
  color: theme.colors.grey[40],
  fontSize: theme.fontSizes[3],
  top: `calc((100% - ${theme.fontSizes[3]}) / 2)`,
  margin: `0 ${theme.space[3]}`,
  zIndex: 2,
})

export type MemberListFilterProps = React.ComponentPropsWithoutRef<"input">

function MemberListFilter({
  "aria-label": ariaLabel = `Search bar`,
  placeholder = `Enter search term`,
  value = ``,
  onChange,
  onBlur,

  className,
}: MemberListFilterProps) {
  return (
    <div css={{ position: `relative`, width: `100%` }}>
      <MdSearch css={searchIconCss} aria-hidden />
      <StyledInput
        css={filterInputCss}
        aria-label={ariaLabel}
        placeholder={placeholder}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        className={className}
      />
    </div>
  )
}

export default MemberListFilter
